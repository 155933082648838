<template>
  <div class="navigation-form-container">
    <error :errors="articleEpisodeError" />
    <b-overlay :show="formBusy">
      <portlet :title="portletTitle">
        <template v-if="action === 'edit'" v-slot:toolbar>
          <b-button :to="{name: 'articles.episodes.create', params: {aid}}" class="btn btn-label-success btn-sm border-0">
            يېڭىدىن قوشۇش
          </b-button>
        </template>
        <template v-slot:body>
          <b-form ref="permission" @submit.stop.prevent="onSubmit" :model="formData" @reset="onReset">
            <b-form-row>
              <b-col sm="6" md="2">
                <b-form-group id="input-group-is_show" label="ئوچۇقمۇ ؟" label-for="is_show">
                  <b-form-checkbox id="is_show" switch v-model="formData.is_show"/>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="2">
                <b-form-group id="input-group-need_payment" label="ھەقلىقمۇ ؟" label-for="need_payment">
                  <b-form-checkbox id="need_payment" switch v-model="formData.need_payment"/>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="2">
                <b-form-group id="input-group-need_vip" label="VIP تەلەپ قىلامدۇ ؟" label-for="need_vip">
                  <b-form-checkbox id="need_vip" switch v-model="formData.need_vip"/>
                </b-form-group>
              </b-col>
              <b-col v-if="formData.need_payment" sm="6" md="2">
                <b-form-group id="input-group-vip_should_paid" label="VIP ئەزامۇ سېتىۋالامدۇ ؟" label-for="vip_should_paid">
                  <b-form-checkbox id="vip_should_paid" switch v-model="formData.vip_should_paid"/>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-row>
              <b-col md="3" sm="6" >
                <b-form-group id="input-group-title" label=" ماۋزو" label-for="title" >
                  <b-form-input id="title" v-model="formData.title" type="text" placeholder="مەزكۇر قىسىمنىڭ ماۋزوسىنى كىرگۈزۈڭ" />
                </b-form-group>
              </b-col>
              <b-col v-if="!isVoice && 1 === false" md="3" sm="6">
                <b-form-group label="تېما ئادېرسى" >
                  <b-input-group>
                    <b-form-input :readonly="formData.content !== ''" v-b-tooltip :title="formData.content ? 'تەھرىرلىگۈچتىكى مەزمۇننى تازلىۋەتكەندىن كىيىن ئىشلىتەلەيسىز' : ''" style="direction: ltr" v-model="formData.salonUrl" :state="validateState('salonUrl')" aria-describedby="salonUrl-live-feedback" placeholder="سالون يازمىسى قىسقا ئادېرسىنى چاپلاپ مەزمۇنغا ئېرىشىڭ" />
                    <b-input-group-append>
                      <b-button :disabled="!formData.salonUrl" @click="handleSalonInfo" variant="primary"> ئېرىشىش </b-button>
                    </b-input-group-append>
                    <b-form-invalid-feedback id="salonUrl-live-feedback">
                      سالون تېما ئادېرسىنى توغرا كىرگۈزۈڭ
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col v-if="isVoice" md="3" sm="6">
                <b-form-group :state="validateState('media_source')" aria-describedby="media_source-live-feedback" id="input-group-media_source" label="ئاۋاز مەنبەسى" label-for="media_source">
                  <b-form-radio-group id="media_source" v-model="formData.media_source" :options="mediaSource" buttons name="radios-btn-default" />
                  <b-form-invalid-feedback id="media_source-live-feedback">
                    ئاۋاز مەنبەسىنى تاللاڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col v-if="formData.need_payment" md="3" sm="6" >
                <b-form-group id="input-group-price" label="باھاسى" label-for="price" >
                  <b-input-group prepend="￥">
                    <b-form-input :state="validateState('price')" aria-describedby="price-live-feedback" style="direction: ltr" min="0.1" step="0.1" id="price" v-model="formData.price" type="number" placeholder="مەزكۇر قىسىمنىڭ باھاسىنى كىرگۈزۈڭ" />
                    <b-form-invalid-feedback id="price-live-feedback">
                      مەزكۇر قىسىمنىڭ باھاسىنى كىرگۈزۈڭ
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col v-if="isVoice && (formData.need_payment || formData.need_vip)" md="3" sm="6" >
                <b-form-group label-class="mb-3" id="input-group-free_duration" :label="`ھەقسىز ئاڭلاش ۋاقتى: ${formData.free_duration} مىنۇت `" label-for="free_duration" >
                  <b-form-input id="free_duration" min="0" max="120" v-model="formData.free_duration" :state="validateState('free_duration')" type="range" aria-describedby="free_duration-live-feedback" />
                  <b-form-invalid-feedback id="free_duration-live-feedback">
                    ھەقسىز ئاڭلاشقا بولىدىغان ۋاقىت ئۇزۇنلۇقنى تاللاڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="isVoice">
              <b-col v-if="formData.media_source === 'we_chat_news'" md="3" sm="6">
                <b-form-group label="تېما ئادېرسى" >
                  <b-input-group>
                    <b-form-input style="direction: ltr" v-model="formData.salonUrl" :state="validateState('salonUrl')" aria-describedby="salonUrl-live-feedback" placeholder="ئاۋازلىق ئەسەر قىستۇرۇلغان تېمىنىڭ قىسقا ئادېرسىنى چاپلاڭ" />
                    <b-input-group-append>
                      <b-button :disabled="!formData.salonUrl" @click="handleSalonVoice" variant="primary"> ئېرىشىش </b-button>
                    </b-input-group-append>
                    <b-form-invalid-feedback id="salonUrl-live-feedback">
                      سالون تېما ئادېرسىنى توغرا كىرگۈزۈڭ
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col v-if="action === 'create'" md="6" sm="6" >
                <b-form-group id="input-group-play_url" label="قويۇش ئادېرسى" label-for="play_url" >
                  <b-form-textarea style="direction: ltr" id="play_url" rows="5" v-model="formData.play_url" :state="validateState('play_url')" aria-describedby="play_url-live-feedback" :placeholder="placeholder" />
                  <b-form-invalid-feedback id="play_url-live-feedback">
                    {{ placeholder }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col v-if="action === 'edit'" md="6" sm="6" >
                <b-form-group id="input-group-play_url" label="قويۇش ئادېرسى" label-for="play_url" >
                  <b-form-input id="play_url" style="direction: ltr" v-model="formData.play_url" :state="validateState('play_url')" aria-describedby="play_url-live-feedback" :placeholder="placeholder" />
                  <b-form-invalid-feedback id="play_url-live-feedback">
                    {{ placeholder }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="!isVoice">
              <b-col cols="12">
                <b-form-group id="input-group-content" label="ئەسەر مەزمۇنى" label-for="content" :state="validateState('content')" aria-describedby="content-live-feedback" >
                  <editor id="editor" ref="editor" v-model="formData.content" />
                  <b-form-invalid-feedback id="content-live-feedback">
                    ئەسەر مەزمۇنىنى كىرگۈزۈڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-button id="submit" type="submit" variant="primary">
              ساقلاش
            </b-button>
            <b-button class="ml-2" type="reset" variant="danger">
              بىكار قىلىش
            </b-button>
          </b-form>
        </template>
      </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "../../partials/content/Portlet";
  import Error from "../../partials/widgets/Error";
  import Editor from "../../partials/content/Editor";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import salonVoiceMixin from "../../../mixins/common/salonVoiceMixin";
  import salonInfoMixin from "../../../mixins/common/salonInfoMixin";
  import validateStateMixin from "../../../mixins/common/validateStateMixin";
  import articleEpisodeMixin from "../../../mixins/articleEpisodeMixin";
  import { validationMixin } from "vuelidate";
  import { required, requiredIf, minValue, url } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import { mapGetters } from "vuex";
  import {SET_ARTICLE_EPISODE_ERROR} from "../../../store/types";
  export default {
    name: "EpisodeForm",
    mixins: [ formBusyMixin, validationMixin, validateStateMixin, salonInfoMixin, articleEpisodeMixin, salonVoiceMixin, sweetAlertMixin ],
    components: { Portlet, Error, Editor },
    props: {
      action: {
        type: String,
        default: 'create'
      },
      isVoice: {
        type: Boolean,
      }
    },
    validations(){
      return {
        formData: {
          free_duration: { required: requiredIf(()=>{
            return this.isVoice && (this.formData.need_vip || this.formData.need_payment);
          })},
          media_source: { required: requiredIf(()=>{
            return this.isVoice;
          })},
          play_url: { required: requiredIf(()=>{
            return this.isVoice;
          })},
          salonUrl: { url: requiredIf(()=>{
            return this.formData.media_source === 'we_chat_news';
          })},
          price: { required: requiredIf(formData=>{
            return formData.need_payment;
          })},
          content: {
            required: requiredIf(()=>{
              return !this.isVoice;
            })
          }
        }
      }
    },
    computed: {
      portletTitle(){
        if ( this.action === 'create' )
          return 'يېڭىدىن قىسىم قوشۇش';
        else if ( this.action === 'edit' )
          return 'قىسىم ئۇچۇرىنى تەھرىرلەش';
      },
      placeholder(){
        if ( this.action === 'create'){
          if ( this.formData.media_source === 'mp3' )
            return 'ئاۋازلىق ئەسەرنىڭ بىۋاستە قويۇش ئادېرسىنى كىرگۈزۈڭ، بىر قۇرغا بىر قىسىمنىڭ ئادېرسىنى كىرگۈزۈپ بىرلا قېتىمدا كۆپ قىسىم قوشالايسىز.';
          else if ( this.formData.media_source === 'we_chat_news' )
            return 'ئوڭ تەرەپكە تېما ئادېرسىنى كىرگۈزۈپ قويۇش ئادېرسىغا ئېرىشىڭ، بىر قۇرغا بىر قىسىمنىڭ ئادېرسىنى كىرگۈزۈپ بىرلا قېتىمدا كۆپ قىسىم قوشالايسىز.';
        }else {
          if ( this.formData.media_source === 'mp3' )
            return 'ئاۋازلىق ئەسەرنىڭ بىۋاستە قويۇش ئادېرسىنى كىرگۈزۈڭ.';
          else if ( this.formData.media_source === 'we_chat_news' )
            return 'ئوڭ تەرەپكە تېما ئادېرسىنى كىرگۈزۈپ قويۇش ئادېرسىغا ئېرىشىڭ.';
        }

      },
      ...mapGetters(["articleEpisodeError"]),
      aid(){
        let { aid } = this.$route.params;
        return aid;
      }
    },
    mounted() {},
    created() {
      if (this.action === 'edit'){
        let { aid, id } = this.$route.params;
        this.findArticleEpisode(aid, id)
        .then(response=>{
          let { data } = response;
          this.formData = data;
          this.formData.media_source = 'mp3';
        }).catch(()=>{
          let { aid } = this.$route.params;
          this.$router.replace({name: 'articles.episodes.list', params: {aid}});
        })
      }
    },
    data(){
      return {
        formBusy: false,
        formData: {
          title: '',
          need_payment: false,
          need_vip: false,
          vip_should_paid: false,
          price: null,
          free_duration: 0,
          media_source: 'mp3',
          play_url: null,
          is_show: true,
          content: '',
          salonUrl: null
        },
        mediaSource: [
          {text: 'بىۋاستە قويۇش ئادېرسى', value: 'mp3'},
          {text: 'سالون تېمىسىدىن', value: 'we_chat_news'},
        ],
      }
    },
    methods: {
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return this.warning('تولدۇرۇشقا تېگىشلىك جەدىۋەلنى تۇلۇق تولدۇرۇڭ');
        }
        let { aid } = this.$route.params;
        if (this.action === 'create'){
          this.createArticleEpisode(aid, this.formData)
            .then(()=>{
              return this.$router.replace({name: "articles.episodes.list", params: {aid}});
            })
        }else if ( this.action === 'edit' ){
          this.updateArticleEpisode(aid, this.formData)
            .then(()=>{
              this.success('تەھرىرلەش مۇۋاپىقىيەتلىك بولدى!');
            })
        }
      },
      onReset(){
        let { aid } = this;
        return this.$router.replace({ name: "articles.episodes.list", params: {aid} });
      },
      onInputEditorContent(content){
        this.formData.content = content;
      },
      handleSalonVoice(){
        let { salonUrl } = this.formData;
        if ( ! salonUrl ) return;
        this.getSalonVoice(salonUrl, SET_ARTICLE_EPISODE_ERROR)
          .then(response=>{
            let { data } = response;
            let { data:urls } = data || {};
            urls = urls ? urls : [];
            if ( this.action === 'create' )
              this.formData.play_url = urls.join("\r");
            else
              this.formData.play_url = urls.length > 0 ? urls[0] : '';
            console.log(data);
          })
      },
      handleSalonInfo(){
        let { salonUrl } = this.formData;
        if ( ! salonUrl ) return;
        this.getSalonInfo(salonUrl, SET_ARTICLE_EPISODE_ERROR)
          .then(response=>{
            let { data } = response;
            let { content } = data.data || {};
            this.formData.content = content.replace(new RegExp(/data-src/, "gm"), 'src');
          })
      }
    }
  }
</script>
